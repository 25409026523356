import clsx from 'clsx';
import React from 'react';
import DotSpinner from '@icons/i-dots-spinner.svg';

interface Props {
  className?: string;
}

export const DotsRoundLoader: React.FC<Props> = props => {
  const { className } = props;
  return <DotSpinner className={clsx(className, 'size-8 text-white')} />;
};

export enum AccountSectionSlug {
  DASHBOARD = 'dashboard',
  MARKETING = 'marketing',
  WITHDRAWAL = 'withdrawal',
  GAMES = 'games',
}

export enum ChallengeDetailsSection {
  MATCHES_HISTORY = 'matches-history',
  CONTRIBUTIONS = 'contributions',
}

import type { GameId } from '../../../__generated/types';

import { env } from 'next-runtime-env';
import queryString from 'query-string';

import { NString } from '../../types/lang.types';
import { AccountSectionSlug, ChallengeDetailsSection } from '../../types/path.types';

export const genHomePagePath = (): string => {
  return '/';
};

export const genAccountPath = (section: AccountSectionSlug): string => {
  return `/streamer/account/${section}`;
};

export const genAccountGameConnectPath = (section: GameId): string => {
  if (section === 'LOL' || section === 'TFT') {
    return `/streamer/account/games/riot`;
  }
  return `/streamer/account/games/${section.toLowerCase()}`;
};

export const genChallengeDetailsPath = (challengeId: string, section?: ChallengeDetailsSection): string => {
  return `/challenge/${challengeId}/${section || ChallengeDetailsSection.MATCHES_HISTORY}`;
};

export const genOnboardingPath = (): string => {
  return '/streamer/onboarding';
};

export const genStreamerPagePath = (streamerId: string): string => {
  return `/streamer/${streamerId}`;
};

export const genStreamerPageAbsolutePath = (streamerId: string): string => {
  return window.location.origin + genStreamerPagePath(streamerId);
};

export const genNewChallengePagePath = (streamerId: string, presetId?: NString): string => {
  const searchString = queryString.stringify({ presetId: presetId || undefined });
  return `/streamer/${streamerId}/new-challenge${searchString ? `?${searchString}` : ''}`;
};

export const genChallengePaymentConfirmationPagePath = (challengeId: string, type: 'failed' | 'success'): string => {
  const baseUrl = window.location.origin + genChallengeDetailsPath(challengeId, ChallengeDetailsSection.CONTRIBUTIONS);
  if (type === 'failed') {
    return baseUrl + `?payment=failed&contribution_id={{contribution_id}}`;
  }
  return baseUrl + `?payment=success&contribution_id={{contribution_id}}`;
};

export const genDocumentPath = (
  filename: 'privacy-policy.pdf' | 'terms-and-conditions.pdf' | 'white-paper.pdf'
): string => {
  return `${env('NEXT_PUBLIC_HOST')}/docs/${filename}`;
};

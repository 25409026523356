'use client';
import * as React from 'react';
import { FC } from 'react';

import { AppLogo } from '../../../shared/ui/logo/app-logo/app-logo';

import { FooterCopyright } from './footer-copyright/footer-copyright';
import { FooterLinksSection } from './footer-links-section/footer-links-section';
import { FooterQuote } from './footer-quote/footer-quote';

export const Footer: FC = () => {
  return (
    <footer className="mt-40 w-full grow-0 border-t bg-dark-800 p-4 shadow md:flex md:items-center md:justify-between md:p-6">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="gap-4 md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <AppLogo className="mb-4" variant={'white'} />
            <FooterQuote />
          </div>
          <FooterLinksSection />
        </div>
        <div className="mt-4 text-right sm:mx-auto">
          <div className="sm:flex sm:items-center sm:justify-between">
            <FooterCopyright />
          </div>
        </div>
      </div>
    </footer>
  );
};

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import React from 'react';

import DiscordIcon from '@icons/i-discord.svg';
import EmailIcon from '@icons/i-email.svg';
import TwitterIcon from '@icons/i-twitter.svg';
import { EMAIL_SUPPORT } from '../../../../shared/constants';
import { genDocumentPath } from '../../../../shared/lib/generators/path.generator';
import { Button } from '../../../../shared/ui/buttons/button/button';
import { Link } from '../../../../shared/ui/navigation/link/link';

interface Props {
  className?: string;
}

export const FooterLinksSection: React.FC<Props> = props => {
  const { className } = props;
  const t = useTranslations();
  return (
    <ul
      className={clsx(className, 'grid grid-flow-col grid-rows-[min-content_min-content_min-content] gap-x-20 gap-y-2')}
    >
      <li>
        <Button variant="simple" as={Link} href={t('Discord.defaultInviteLink')} target="_blank" icon={<DiscordIcon />}>
          {t('Footer.discord')}
        </Button>
      </li>
      <li>
        <Button variant="simple" as={Link} href={`mailto: ${EMAIL_SUPPORT}`} target="_blank" icon={<EmailIcon />}>
          {t('Footer.email')}
        </Button>
      </li>
      <li>
        <Button variant="simple" as={Link} href={t('')} target="_blank" icon={<TwitterIcon />}>
          Twitter
        </Button>
      </li>
      <li>
        <Button variant="simple" as={Link} href={genDocumentPath('white-paper.pdf')} target="_blank">
          {t('Footer.whitePaper')}
        </Button>
      </li>
      <li>
        <Button variant="simple" as={Link} href={genDocumentPath('terms-and-conditions.pdf')} target="_blank">
          {t('Footer.terms')}
        </Button>
      </li>

      <li>
        <Button variant="simple" as={Link} href={genDocumentPath('privacy-policy.pdf')} target="_blank">
          {t('Footer.policy')}
        </Button>
      </li>
    </ul>
  );
};

import clsx from 'clsx';
import * as React from 'react';

import { genLogoUrl } from './app-logo.util';

interface Props {
  className?: string;
  variant: 'colorful' | 'white';
}

export const AppLogo: React.FC<Props> = props => {
  const { className, variant } = props;
  return (
    <img
      className={clsx(className, 'flex flex-nowrap items-center gap-2')}
      src={genLogoUrl(variant)}
      alt={'tryme.gg'}
    />
  );
};

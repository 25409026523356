import clsx from 'clsx';
import React from 'react';

interface Props {
  className?: string;
}

export const FooterCopyright: React.FC<Props> = props => {
  const { className } = props;
  return <span className={clsx(className)}>TryMe.gg © {new Date().getFullYear()} </span>;
};

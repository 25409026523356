'use client';

import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import * as React from 'react';
import { FC } from 'react';

import DiscordIcon from '@icons/i-discord.svg';
import { useSignIn } from '../../../features/sign-in/model/use-sign-in';
import { genAccountPath, genHomePagePath } from '../../../shared/lib/generators/path.generator';
import { Logger } from '../../../shared/lib/logger/logger';
import { AccountSectionSlug } from '../../../shared/types/path.types';
import { Button } from '../../../shared/ui/buttons/button/button';
import { CircleLoader } from '../../../shared/ui/loaders/circle-loader/circle-loader';
import { AppLogo } from '../../../shared/ui/logo/app-logo/app-logo';
import { Link } from '../../../shared/ui/navigation/link/link';
import { Avatar } from '../../../shared/ui/surfaces/avatar/avatar';

interface Props {
  hideInteraction?: boolean;
}

export const TopBar: FC<Props> = props => {
  const { hideInteraction } = props;
  const { status, data } = useSession();
  const { signIn } = useSignIn();
  const t = useTranslations();
  const userImage = data?.user?.image || '';

  const onLoginButtonClick = async (): Promise<void> => {
    try {
      await signIn();
    } catch (e) {
      Logger.error('[TopBar]:[onClickHandler]:error', e);
    }
  };

  return (
    <nav className="container mt-8 grow-0">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between gap-2">
        <div className="flex items-center gap-3 transition-opacity">
          <Link href={genHomePagePath()}>
            <AppLogo variant="colorful" className="hover:opacity-80" />
          </Link>
        </div>
        {!hideInteraction && (
          <div className="flex items-center justify-center gap-4">
            <Button variant={'simple'} as={Link} href={t('Discord.defaultInviteLink')} icon={<DiscordIcon />}>
              {t('Button.joinDiscord')}
            </Button>

            {status === 'loading' && <CircleLoader />}
            {status === 'authenticated' && (
              <>
                <Button
                  variant={'simple'}
                  as={Link}
                  href={genAccountPath(AccountSectionSlug.DASHBOARD)}
                  icon={<Avatar src={userImage} />}
                >
                  {t('Button.myAccount')}
                </Button>
              </>
            )}
            {status === 'unauthenticated' && (
              <>
                <Button name="login" variant="outlined" className="min-w-[100px]" onClick={onLoginButtonClick}>
                  {t('Button.logIn')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import React from 'react';

interface Props {
  className?: string;
}

export const FooterQuote: React.FC<Props> = props => {
  const { className } = props;
  const t = useTranslations();
  return (
    <div className={clsx(className, 'mb-4 max-w-lg')}>
      <p className="caption mb-1 text-left text-white">{t('Footer.quoteText')}</p>
      <div className="w-full text-left">❝&nbsp;{t('Footer.quoteAuthor')}</div>
    </div>
  );
};

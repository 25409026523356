import clsx from 'clsx';
import React from 'react';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  src: string;
}

export const Avatar: React.FC<Props> = props => {
  const { className, src, alt } = props;
  return <img className={clsx(className, 'rounded-full bg-white')} src={src} alt={alt} />;
};

'use client';
import clsx from 'clsx';
import React from 'react';

import { PolymorphicRef } from '../../../types/polymorphic.types';
import { DotsRoundLoader } from '../../loaders/dots-round-loader/dots-round-loader';

import { ButtonComponent, ButtonProps } from './button.types';

export const Button: ButtonComponent = React.forwardRef(
  <C extends React.ElementType = 'button'>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => {
    const { className, children, loading, active, disabled, variant, as: Component = 'button', icon, ...rest } = props;
    return (
      <Component
        className={clsx(
          className,
          'body-3-bold box-border flex h-12 items-center whitespace-nowrap text-center text-white transition-colors',

          disabled && 'pointer-events-none cursor-not-allowed',
          loading && 'pointer-events-none cursor-not-allowed',

          variant === 'contained' &&
            ' min-w-[292px] justify-center bg-red-400 px-10 hover:bg-red-200 active:bg-red-800',
          variant === 'contained' && disabled && '!bg-red-200 text-white-alpha-40',

          variant === 'outlined' &&
            'inset-0 min-w-[180px] justify-center border border-red-400 bg-transparent px-10 hover:outline hover:outline-2 hover:outline-red-400 active:bg-red-400',
          variant === 'outlined' && disabled && '!text-white-alpha-40',

          variant === 'ghost' &&
            'min-w-[292px] gap-1.5 border-l-4 border-transparent bg-transparent pl-8 hover:border-dark-600 hover:bg-dark-600 active:border-dark-400 active:bg-dark-400',
          variant === 'ghost' && active && '!border-red-400 !bg-white-alpha-8',
          variant === 'ghost' && disabled && 'text-white-alpha-40',

          variant === 'simple' && 'gap-1.5 bg-transparent hover:text-white-alpha-72 active:text-white-alpha-40',
          variant === 'simple' && disabled && 'text-white-alpha-24'
        )}
        type="button"
        ref={ref}
        {...rest}
      >
        <div className={clsx('flex items-center gap-3', loading && 'invisible')}>
          {icon && <div className={clsx('[&>*]:size-full [&>*]:shrink-0', 'size-6')}>{icon}</div>}
          {children}
        </div>
        {loading && <DotsRoundLoader className="absolute" />}
      </Component>
    );
  }
);

import { signIn } from 'next-auth/react';

import { genAccountPath } from '../../../shared/lib/generators/path.generator';
import { AccountSectionSlug } from '../../../shared/types/path.types';

export const useSignIn = (): { signIn: () => Promise<unknown> } => {
  return {
    signIn: () => signIn('twitch', { callbackUrl: genAccountPath(AccountSectionSlug.DASHBOARD) }),
  };
};
